@tailwind base;
@tailwind components;
@tailwind utilities;

.map {
  -webkit-filter: grayscale(90%);
  -moz-filter: grayscale(90%);
  -ms-filter: grayscale(90%);
  -o-filter: grayscale(90%);
  filter: grayscale(90%);
}
